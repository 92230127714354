@import '../../marketplace.css';

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.questionTitle {
  font-size: 18px;
}

.uniTitle {
  font-size: 30px;
  height: 150px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  line-height: 30px;
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  width: 100%;

  @media (--viewportMedium) {
    width: 45%;
  }
}
.schoolsLocations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    width: 80%;
  }
}
.schools {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 10px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
  }
}

/* An image wrapper that defines the images aspect ratio */

.locationImage {
  height: 70px;
  width: auto;
  /* Layout - image will take space defined by aspect ratio wrapper */
}

.linkText {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.stepTitle {
  font-size: 22px;
}
