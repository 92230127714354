@import '../../marketplace.css';

.backgroundLight {
  stop-color: var(--bannedColorLight);
}

.backgroundDark {
  stop-color: var(--bannedColorDark);
}

.foregroundFill {
  fill: var(--matterColorLight);
}

.foregroundStroke {
  stroke: var(--matterColorLight);
}
