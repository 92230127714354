@import '../../marketplace.css';

.buttonLink {
  @apply --marketplaceButtonStyles;
}

.buttonLinkPrimary {
  @apply --marketplaceButtonStylesPrimary;
}

.buttonLinkSecondary {
  @apply --marketplaceButtonStylesSecondary;
}

.customButton {
  @apply --marketplaceButtonStyles;
  padding: 0;
  border-radius: 8px;
  height: 100px;
}

.customIcon {
  width: 24px;
  height: 24px;
  stroke: #fff;
  margin-right: 10px;
}
