@import '../../marketplace.css';

.root {
}

.marketplaceStroke {
  stroke: var(--marketplaceColor);
}

.attentionStroke {
  stroke: var(--attentionColor);
}
