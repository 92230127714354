@import '../../marketplace.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.spec {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.category {
  margin-top: 12px;
}

.description {
}

.lanTitle {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    padding-top: 6px;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
}

.secondSection {
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.firstSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.column legend {
  font-size: 18px;
  margin-bottom: 10px;
}

.column label {
  /* font-size: 18px; */
  /* margin-bottom: 10px; */
}

.secondSection {
  font-size: 16px;
  margin-bottom: 6px;
}

.link {
  margin-bottom: 8px;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.features {
  margin-bottom: 15px;
}

.uniBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}

.lanBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  /* margin-right: 20px; */
  /* margin-top: 15px; */
}
.lanBlock label {
  margin-bottom: 7px;
}

.uniBlockWrapper {
  display: flex;
  flex-direction: row;
}

.fieldArrayRemove {
  margin-left: 15px;
  margin-top: 15px;
}

.lanFieldArrayRemove {
  margin-left: 15px;
}

.lanContainer {
  margin-bottom: 10px;
}
