@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  position: relative;

  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.backIcon {
  position: absolute;
  top: 30%;
  right: 0;
  opacity: 0.1;
}

.icon {
  width: 80px;
  height: 80px;

  @media (--viewportMedium) {
    width: 130px;
    height: 130px;
  }
}
