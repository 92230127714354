@import '../../marketplace.css';

:root {
  /*
      These variables are available in global scope through ":root"
      element (<html> tag). Variables with the same names are going to
      overwrite each other if CSS Properties' (PostCSS plugin)
      configuration "preserve: true" is used - meaning that variables
      are left to CSS bundle. We are planning to enable it in the future
      since browsers support CSS Properties already.
   */

  --Overlay_coverEverything: {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.root {
  /* Positioning */
  @apply --Overlay_coverEverything;
}

.overlay {
  /* Positioning */
  @apply --Overlay_coverEverything;

  /* Overlay background style */
  background-color: var(--matterColorLight);
  opacity: 0.9;
}

.overlayContent {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);

  /* Positioning */
  @apply --Overlay_coverEverything;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media (--viewportMedium) {
  .overlayContent {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.message {
  max-width: 220px;
  text-align: center;
}

.errorMessage {
  composes: message;
  color: var(--failColor);
}
